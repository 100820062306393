.journal_search_input {
  width: 30%;
  margin-top: 1%;
  margin-left: 10px;
}
.journal_container {
  position: relative;
  width: 100%;
}
.journal_search_container {
  display: flex;
  justify-content: center;
}
.journal_search {
  width: 15%;
  margin-top: 1%;
  margin-left: 10px;
}
.journal_list {
  margin-top: 1%;
  margin-left: 1%;
  margin-right: 1%;
  margin-bottom: 3%;
}
.journal_viewer_div {
  margin-top: 1%;
  width: 100%;
}
