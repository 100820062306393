.carousel_edit {
  line-height: 50px;
}
.carousel_img {
  width: 100%;
  height: 400px;
  align-items: center;
}
.carousel_apply_changes {
  margin-left: 10px;
}
