.aboutus_sider {
  color: black;
}

.aboutus_content {
  text-align: justify;
  text-justify: inter-word;
  color: black;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}
.aboutus_layout {
  height: auto;
}
.aboutus_profile {
  padding: 10%;
  height: 100px;
  width: 100px;
}
.aboutus_name {
  color: blue;
  font-weight: bold;
  margin-top: -5px;
}
.aboutus_role {
  color: black;
  margin-top: -5px;
}
