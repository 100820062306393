html,
body,
#root {
  height: 100%; /* Ensure all parent elements have 100% height */
}
html,
body,
#root {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

.HomeContainer {
  height: 100%;
}
.Layout {
  height: 100%;
}

.logo {
  height: 75px;
  width: 100px;
  padding-top: 20px;
  margin-left: -40px;
}

.content-div {
  background: white;
}

.content {
  background-color: white;
  overflow: initial;
}

.Header {
  display: flex;
  align-items: center;
  background: white;
  position: sticky;
  top: 0;
  z-index: 1;
  width: 100%;
  display: flex;
}

.Menu_bar {
  flex: 1;
  min-width: 0;
}

.Footer {
  text-align: center;
  bottom: 0;
  background-color: white;
  padding: 1px 1px;
}
.header_bar {
  background-color: blue;
  min-height: 0.5px;
}
.content_container {
  background: white;
}
