.announcement_card {
  border: 2px solid blue;
  height: 100%;
}
.announcement_list_card {
  width: 50%;
  align-items: center;
  justify-content: center;
  padding: 10px 30px;
}
.announcement_row {
}
.ant-card-head-title {
  color: blue;
  font-weight: bold;
}
