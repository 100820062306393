.aim_edit_component {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
.sub_heading_box {
  width: 70%;
}
.desc_box {
  width: 300px;
}
