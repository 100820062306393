.submission_container {
  position: relative;
  width: 100%;
  font-size: 15px;
  padding-left: 20px;
  padding-right: 20px;
}

.submission-guidelines-text {
  text-align: justify;
  margin-left: 10px;
}
.guidelines-heading {
  color: blue;
}
