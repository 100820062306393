.journal_list_card {
  width: 50%;
  padding: 10px 30px;
  align-items: center;
  justify-content: center;
}
.journal_card {
  border: 2px solid blue;
  align-items: center;
  justify-content: center;
}
