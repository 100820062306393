.card {
  /* position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 50%;
  border: 1px solid blue;
  padding: 10px; */
  width: 60%; /* Adjust the width as needed */
  border: 1px solid blue;
  max-width: 800px; /* Maximum width for larger screens */
  margin: 0 auto; /* Center horizontally */
}
.form_title {
  margin-top: -15px;
  font-weight: bold;
  color: blue;
  text-decoration: underline;
}
.raise_query_div {
  display: flex;
  justify-content: center; /* Horizontal centering */
  align-items: center;
  height: 80vh;
}
