.heading_text {
  display: flex;
  margin-left: 10px;
  color: blue;
}
.description_text {
  display: flex;
  padding-left: 10px;
  padding-right: 10px;
  font-size: medium;
  text-align: justify;
}
.aim_container {
  background: white;
}
