.logo_upload_div {
  line-height: 75px;
  height: 100%;
}
.logo_upload_header {
  color: blue;
}
.logo_watermark {
  height: 100%;
}
