.pdfLayoutStyle {
  border-radius: 8;
  overflow: hidden;
  width: "100%";
}
.pdfSiderStyle {
  text-align: center;
  background-color: white;
  line-height: 120px;
}
.pdfContentStyle {
  text-align: center;
  min-height: 120;
  line-height: 120px;
  background-color: white;
}
.metadata_text {
  color: blue;
  font-weight: bold;
}
