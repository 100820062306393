.adminconsole_div {
  height: 80vh;
  margin: 25px 10px;
  border: 1px solid blue;
}
.adminconsole_sider {
  text-align: center;
  line-height: 120px;
  height: 100%;
}
.adminconsole_layout {
  height: 100%;
}
.adminconsole_content {
  text-align: center;
  min-height: 120;
  line-height: 120px;
}
.adminconsole_menu {
}
