.home_container {
}
.home_card_component {
  display: flex;
  align-items: center;
  justify-content: center;
}
.marquee_text {
  color: blue;
  font-weight: bold;
  margin-bottom: -7px;
}
